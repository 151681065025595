import { Store } from "react-notifications-component";
import * as yup from "yup";
import { FormattedMessage } from "react-intl";
import { ACCESS_TOKEN, BASE_URL, EN, IT, LANGUAGE_ACCESS, initialPopupState } from "./constants";
import { DateTime } from "luxon";
import LANG_IT from 'date-fns/locale/it';
import LANG_EN_US from 'date-fns/locale/en-US';

export const getAuthUser = () => {
  let token = localStorage.getItem(ACCESS_TOKEN);
  if (token) {
    return JSON.parse(token).user;
  } else {
    return false;
  }
};

export const getToken = () => {
  let token = localStorage.getItem(ACCESS_TOKEN);
  if (token) {
    return JSON.parse(token).access_token;
  } else {
    return false;
  }
};

export const setToken = (access_token) => {
  localStorage.setItem(ACCESS_TOKEN, JSON.stringify(access_token));
};

export const removeToken = () => {
  localStorage.removeItem(ACCESS_TOKEN);
};

export const success_alert = (message, title) => {
  Store.addNotification({
    title: title ? title : "",
    message: message,
    className: "noti",
    type: "success",
    insert: "bottom",
    container: "bottom-right",
    animationIn: ["animate__animated", "animate__fadeInUp"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 7000,
      onScreen: true,
      showIcon: true,
    },
  });
};

export const error_alert = (message, title) => {
  Store.addNotification({
    title: title ? title : "",
    message: message,
    className: "noti",
    type: "danger",
    insert: "bottom",
    container: "bottom-right",
    animationIn: ["animate__animated", "animate__slideInRight"],
    animationOut: ["animate__animated", "animate__slideOutBottom"],
    dismiss: {
      duration: 7000,
      onScreen: true,
      showIcon: true,
    },
  });
};

export const warning_alert = (message, title) => {
  Store.addNotification({
    title: title ? title : "",
    message: message,
    type: "warning",
    insert: "bottom",
    container: "bottom-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 7000,
      onScreen: true,
      showIcon: true,
    },
  });
};

export function stringNotEmpty() {
  return yup.mixed().test({
    name: "stringNotEmpty",
    exclusive: false,
    message: <FormattedMessage id="required" defaultMessage="Required" />,
    test: function (value) {
      if (value !== undefined && value !== false) {
        return value.trim() !== "";
      } else if (value === undefined) {
        return false;
      }
    },
  });
}

export const selectStyles = (error) => ({
  control: (provided, state) => ({
    ...provided,
    backgroundColor: state.isDisabled ? "#fff" : "#fff",
    borderRadius: "0.375rem",
    border: state.isDisabled
      ? "2px solid #e4e4e4"
      : state.isFocused
      ? "2px solid #4c0788"
      : state.isSelected
      ? "2px solid #ef4444"
      : state.hasValue
      ? "2px solid #4c0788"
      : error
      ? "2px solid #ef4444"
      : "2px solid #cbd5e1",
    // boxShadow: state.isFocused ? "0px 0px 6px #4c0788" : "none",
    "&:hover": {
      border: "2px solid #4c0788",
      boxShadow: "0px 0px 4px #4c0788",
    },
    fontSize: "1rem",
    "@media (max-width:1024px)": {
      fontSize: "0.875rem",
    },
  }),
  option: (provided, state) => {
    return {
      ...provided,
      background: state.isSelected ? "#4c0788" : "white",
      color: state.isSelected ? "#000" : "#500",
      "&:hover": {
        background: "#4c0788",
        color: "#fffff",
      },
      fontSize: "1rem",
      "@media (max-width:1024px)": {
        fontSize: "0.875rem",
      },
    };
  },

  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition };
  },
});
export const selectStylesFilter = (error) => ({
  control: (provided, state) => ({
    ...provided,
    height: "12px",
    fontSize: "12px",
    backgroundColor: state.isDisabled ? "#fff" : "#fff",
    borderRadius: "0.375rem",
    border: state.isDisabled
      ? "2px solid #e4e4e4"
      : state.isFocused
      ? "2px solid #4c0788"
      : state.isSelected
      ? "2px solid #ef4444"
      : state.hasValue
      ? "2px solid #4c0788"
      : error
      ? "2px solid #ef4444"
      : "2px solid #cbd5e1",
    boxShadow: state.isFocused ? "0px 0px 6px #4c0788" : "none",
    "&:hover": {
      border: "2px solid #4c0788",
      boxShadow: "0px 0px 6px #4c0788",
    },
  }),

  option: (provided, state) => {
    return {
      ...provided,
      background: state.isSelected ? "#4c0788" : "white",
      color: state.isSelected ? "#000" : "#500",
      fontSize: "12px",
      "&:hover": {
        background: "#4c0788",
        color: "#500",
      },
    };
  },

  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition, fontSize: "12px" };
  },
});

export const selectFilterStyles = (error, larger) => ({
  control: (provided, state) => ({
    ...provided,
    height: larger ? "120px" : "12px",
    fontSize: "12px",
    backgroundColor: state.isDisabled ? "#fff" : "#fff",
    borderRadius: "0.375rem",
    border: state.isDisabled
      ? "2px solid #e4e4e4"
      : state.isFocused
      ? "2px solid #C23D36"
      : state.isSelected
      ? "2px solid #C23D36"
      : state.hasValue
      ? "2px solid #C23D36"
      : error
      ? "2px solid #ef4444"
      : "2px solid #cbd5e1",
    boxShadow: state.isFocused ? "0px 0px 6px #C23D36" : "none",
    "&:hover": {
      border: "2px solid #C23D36",
      boxShadow: "0px 0px 6px #C23D36",
    },
  }),

  option: (provided, state) => {
    return {
      ...provided,
      background: state.isSelected ? "#C23D36" : "white",
      color: state.isSelected ? "#000" : "#500",
      fontSize: "12px",
      "&:hover": {
        background: "#C23D36",
        color: "#500",
      },
    };
  },

  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition, fontSize: "12px" };
  },
});

export const hidePopup = (setState) => {
  setState(initialPopupState);
};

export const phoneRegExp =
  /^((\\+[0-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const isUrl = (string) => {
  const urlPattern =
    /^(?:(?:https?|ftp):\/\/)?(?:www\.)?(?:[\w-]+\.)+[a-z]{2,}(?::\d+)?(?:\/[\w#!:.?+=&%@!-]*)?$/i;
  return urlPattern.test(string);
};

export const getImage = (value) => {
  return BASE_URL + value.substr(1);
};

// export const customStyles = {
//   table: {
//     style: {
//       minWidth: "640px",
//       borderRadius: "8px",
//       boxShadow: "0 0 10px 0 rgba(0,0,0,0.4)",
//     },
//   },
//   tableWrapper: {
//     style: {
//       // display: "table",
//     },
//   },
//   // header: {
//   //   style: {
//   //     fontSize: "24px",
//   //     // backgroundColor: "rgb(232 54 54 / 0.1)",
//   //     minHeight: "56px",
//   //     paddingLeft: "0",
//   //     paddingRight: "8px",
//   //   },
//   // },
//   head: {
//     style: {
//       fontSize: "0.75rem",
//       fontWeight: 200,
//     },
//   },
//   headRow: {
//     style: {
//       // backgroundColor: "#BCE5EF",
//       backgroundColor: "#C23D36",
//       color: "#fff",
//       minHeight: "52px",
//       borderBottomWidth: "1px",
//       borderBottomStyle: "solid",
//     },
//     denseStyle: {
//       minHeight: "32px",
//     },
//   },
//   // headCells: {
//   //   style: {
//   //     paddingLeft: "16px",
//   //     paddingRight: "16px",
//   //   },
//   //   draggingStyle: {
//   //     cursor: "move",
//   //   },
//   // },
//   rows: {
//     style: {
//       flexGrow: 0,
//       fontSize: "0.75rem",
//       color: "#94a3b8",
//       fontWeight: 400,
//       minHeight: "72px", // override the row height
//       minWidth: "min-content",
//     },
//   },
//   cells: {
//     style: {
//       flexGrow: 1,
//       //   color: "#94a3b8",
//       minWidth: "min-content",
//     },
//   },
//   pagination: {
//     style: {
//       fontSize: "0.75rem",
//       fontWeight: "400",
//       minHeight: "0",
//       borderRadius: "10px",
//       marginTop: "10px",
//       border: "none",
//       background: "none",
//       color: "#00000080",
//     },
//     pageButtonsStyle: {
//       height: "auto",
//       width: "auto",
//       padding: "10px 20px",
//       //   background: "white",
//       borderRadius: "10px",
//       marginLeft: "5px",
//       boxShadow: "0 0 3px 0 rgba(0,0,0,0.2)",
//       "&:disabled": {
//         boxShadow: "none",
//         background: "none",
//         fill: "#D1D1D1",
//         "&:hover": {
//           fill: "#C23D36",
//           cursor: "pointer",
//           boxShadow: "none",
//         },
//       },
//       "&:hover": {
//         boxShadow: "0 0 3px 0 rgba(0,0,0,0.4)",
//       },
//     },
//   },
// };

// export const oldCustomStyles = {
//   table: {
//     style: {
//       border: "2px solid #e2e8f0",
//       // borderRadius: "10px",
//     },
//   },
//   headRow: {
//     style: {
//       backgroundColor: "#ffffff",
//       paddingLeft: "10px",
//       paddingRight: "10px",
//     },
//   },
//   rows: {
//     style: {
//       backgroundColor: "white",
//       minHeight: "80px", // override the row height
//       borderBottom: "0px solid #ccc !important",
//       paddingTop: "20px",
//       paddingBottom: "20px",
//       paddingLeft: "10px",
//       paddingRight: "10px",
//     },
//     highlightOnHoverStyle: {
//       transitionDuration: "0.15s",
//       backgroundColor: "#fff",
//     },
//   },
//   headCells: {
//     style: {
//       color: "#94a3b8",
//       textTransform: "capitalize",
//       fontSize: "13px",
//       fontWeight: "600",
//     },
//   },
//   cells: {
//     style: {
//       color: "#000",
//       fontWeight: "400",
//       fontSize: "1rem",
//       lineHeight: "1.5",
//     },
//   },
//   pagination: {
//     style: {
//       fontSize: "12px",
//       color: "#94a3b8",
//       fontWeight: "600",
//       minHeight: "0",
//       borderRadius: "10px",
//       marginTop: "10px",
//       border: "none",
//       background: "none",
//     },
//     pageButtonsStyle: {
//       height: "auto",
//       width: "auto",
//       padding: "10px 20px",
//       background: "white",
//       borderRadius: "10px",
//       marginLeft: "5px",
//       boxShadow: "0 0 3px 0 rgba(0,0,0,0.2)",
//       "&:disabled": {
//         boxShadow: "none",
//         background: "none",
//         "&:hover": {
//           boxShadow: "none",
//         },
//       },
//       "&:hover": {
//         boxShadow: "0 0 3px 0 rgba(0,0,0,0.4)",
//       },
//     },
//   },
// };


// export const customStyles = {
//   rows: {
//       style: {
//       minHeight: '60px', // override the row height
//       borderBottom: "0.8px solid #ccc",
//       }
//   },
//   headCells:{
//       style:{
//           color:"#e83636",
//           fontSize:"14px",
//           // backgroundColor: "#40e0d0",
//           backgroundColor: "#fff",
//           fontWeight: "600"
//       }
//   },
//   cells: {
//       style: {
//          color: "#000",
//          fontWeight: "500",
//          minWidth:'120px'
//       },
//   },
// };

export const customStyles = {
  rows: {
      style: {
      minHeight: '60px', // override the row height
      borderBottom: "0.8px solid #ccc",
      paddingTop:'5px',
      paddingBottom:'5px'
      }
  },
  headRow: {
    style: {
      minHeight: "60px",
      backgroundColor: "white"
    }
  },
  headCells:{
      style:{
          // minHeight: "90px",
          // color:"#4F7284",
          color:"#757373",
          fontSize:"13px",
          textTransform: "uppercase",
          // backgroundColor: "#40e0d0",
          fontWeight: "600",
      }
  },
  cells: {
      style: {
         color: "#000",
         fontWeight: "500",
         minWidth:'120px'
      },
  },
};


export function customStringify(input) {
  // Check if the input is already a string
  if (typeof input === 'string') {
      return input;
  }

  // Use JSON.stringify to convert the object to a JSON string
  let jsonString = JSON.stringify(input, (key, value) => {
      // Check if the value is a string
      if (typeof value === 'string') {
          // Remove quotes around the string
          return value;
      }
      return value;
  });

  return jsonString;
}

export const returnTimeSlots = () => {
  const startTime = DateTime.fromObject({ hour: 0, minute: 0, second: 0 });
    const endTime = DateTime.fromObject({ hour: 23, minute: 59, second: 59 });
    const timeSlots = [];

    let currentTime = startTime;

    while (currentTime <= endTime) {
        const formattedTime = currentTime.toFormat('HH:mm');
        timeSlots.push(formattedTime);
        currentTime = currentTime.plus({ minutes: 15 });
    }

    return timeSlots
}

export const returnBackendTimeObject = (selectedStartTime, slots) => {
    const startTime = DateTime.fromObject({ hour: 0, minute: 0, second: 0 });
    const endTime = DateTime.fromObject({ hour: 23, minute: 59, second: 59 })
    const timeSlots = {};

    let currentTime = startTime;
    let smallest = findSmallestGreaterTime(slots, selectedStartTime);

    while (currentTime <= endTime) {
        const formattedTime = currentTime.toFormat('HH:mm');
        if(formattedTime !== "00:00"){
          
          if(selectedStartTime && slots){
            if(smallest){
              if(currentTime <= DateTime.fromFormat(smallest, "HH:mm")){
                timeSlots[currentTime.minus({minutes: 1}).toFormat('HH:mm')] = {
                  value: formattedTime,
                  isDisabled: false
                } 
              } else{
                timeSlots[currentTime.minus({minutes: 1}).toFormat('HH:mm')] ={
                  value: (
                    <p className="text-gray-500">
                      {formattedTime}
                      <span className="ml-1 text-[10px] font-medium"><FormattedMessage id="overlaps_in_range" defaultMessage={"Overlaps existing range"}/></span>
                    </p>
                  ),
                  isDisabled: true
                } 
              }
            } else{
              timeSlots[currentTime.minus({minutes: 1}).toFormat('HH:mm')] ={
                value: formattedTime ,
                isDisabled: false
              }
            }
          } else{
            timeSlots[currentTime.minus({minutes: 1}).toFormat('HH:mm')] = {
              value: formattedTime ,
              isDisabled: false
            }
          }

        }
        currentTime = currentTime.plus({ minutes: 15 });
    }
    if(smallest){
      if(DateTime.fromFormat("23:59", "HH:mm") <= DateTime.fromFormat(smallest, "HH:mm")){
        timeSlots["23:59"] = {
          value: "24:00",
          isDisabled: false
        }
      }else{
        timeSlots["23:59"] = {
          value: <p className="text-gray-500">
          24:00
          <span className="ml-1 text-[10px] font-medium"><FormattedMessage id="overlaps_in_range" defaultMessage={"Overlaps existing range"}/></span>
          </p>,
          isDisabled: false
        }
      }
    } else{
      timeSlots["23:59"] = {
        value: "24:00",
        isDisabled: false
      }
    }


    return timeSlots
}

function findSmallestGreaterTime(timesArray, currentTime) {
  let smallestGreaterTime = null;
  if(!timesArray){
    return
  }
  for (const time of timesArray) {
    // Skip the current time if it matches the input time
    if (time.start_time === currentTime) {
      continue;
    }

    // Parse the time.start_time string into Luxon DateTime object for comparison
    const parsedTime = DateTime.fromFormat(time.start_time, "HH:mm");
    const parsedCurrentTime = DateTime.fromFormat(currentTime, "HH:mm");

    // Check if the parsed time.start_time is greater than the current time.start_time
    if (parsedTime > parsedCurrentTime) {
      // If smallestGreaterTime is null or the parsed time.start_time is smaller than the current smallestGreaterTime
      if (!smallestGreaterTime || parsedTime < smallestGreaterTime) {
        smallestGreaterTime = parsedTime;
      }
    }
  }

  // Return the smallest time greater than the current time as a string in "HH:mm" format
  return smallestGreaterTime ? smallestGreaterTime.toFormat("HH:mm") : null;
}


export const sortingTimeSlots = (arr,setState)=>{
  arr = arr.sort((a, b) => {
    // Extracting hours and minutes from start_time string
    const [aHours, aMinutes] = a.start_time.split(":").map(Number);
    const [bHours, bMinutes] = b.start_time.split(":").map(Number);

    // Comparing hours
    if (aHours !== bHours) {
        return aHours - bHours; // Sort by hours
    } else {
        return aMinutes - bMinutes; // If hours are the same, sort by minutes
    }
  });
  if(arr&&arr.length>0){
    setState(arr)
  }else{
    setState([])
  }

} 



export const setSelectedLanguage= (data) =>{
  localStorage.setItem(LANGUAGE_ACCESS, JSON.stringify(data));
}
export const getSelectedLanguage= () =>{
  let obj = localStorage.getItem(LANGUAGE_ACCESS);
  if (obj) {
    return JSON.parse(obj);
  } else {
    return {
      type: EN
    }
  }
}


export const calanderLocale =()=>{
  let x = getSelectedLanguage()
  if(x){
    if(x.type === IT){
      return LANG_IT
    }else{
      return LANG_EN_US
    }

  }else{
    return LANG_EN_US
  }

}