import { useContext } from "react"
import React from 'react'
import {motion} from "framer-motion";
import { BasePopupContext } from "./";
import { AiOutlineClose } from "react-icons/ai";
import { date } from "yup";

const SidePopup = ({data}) => {
    // this is a helper component which sets the base for a modal
    const variants = {
        initial: {
            x: "100%"
        },
        enter: {
            x: "0%"
        },
        exit: {
            x: "100%"
        }
    }
    
    const {closePopup} = useContext(BasePopupContext);
    
    
        return(
            <motion.div 
            transition={{
                duration: 1
            }}
            className={`bg-black bg-opacity-50 right-0 top-0 bottom-0 left-0 fixed flex items-stretch justify-end`} style={{zIndex: `${1000 + data.index}`}}>
                <motion.div
                transition={{type: "tween"}}
                initial="initial"
                animate="enter"
                exit="exit"
                variants={variants}
                className={`${data.size ? data.size : "w-full"} overflow-y-auto bg-white shadow-lg border-1 border-gray-200 relative h-full`}>
                    <div className={data.contained ? "px-5 py-8" : ""}>
                        <div>
                            {data.component}
                        </div>
                    </div>
                </motion.div>
            </motion.div>
        )
    }
    

export default SidePopup