import React from 'react'
import { EN, IT } from "../../../constants";
import { useDispatch, useSelector } from "react-redux";
import { set_language } from "../../../redux/slices/languageSlice";
import en from "../../../languages/en.json";
import it from "../../../languages/it.json";
import { FormattedMessage } from 'react-intl';


const LanguageSelection = () => {
  const language = useSelector((state) => state.language)

  const dispatch = useDispatch()


  const handleLanguage = (lang) => {
    if (lang === language.type) {
      return
    }
    if (language.type === EN) {
      let obj = {
        type: IT,
        // messages: it
      }
      dispatch(set_language(obj))
    } else {
      let obj = {
        type: EN,
        // messages: en
      }
      dispatch(set_language(obj))
    }

  }

  return (
    <>
      <div className="text-xs flex justify-center space-x-2 text-primary pt-8">
        <div className={` cursor-pointer ${language.type === EN ? "font-medium underline" : ""} `} onClick={() => handleLanguage(EN)}>
          <FormattedMessage id="english" defaultMessage="English" />
        </div>
        <div className={` cursor-pointer ${language.type === IT ? "font-medium underline" : ""} `} onClick={() => handleLanguage(IT)}>
          <FormattedMessage id="italian" defaultMessage="Italian" />
        </div>
      </div>
    </>
  )
}

export default LanguageSelection