import { createSlice } from "@reduxjs/toolkit";
import { DAYS } from "../../constants";

const initialState = {
    day: DAYS[0],
    dayData: null,
    templates: [],
    selectedTemplate: null,
    templateData: null,
    reload: 0,
}

const weeklyScheduleSlice = createSlice({
    name: "weekly_schedule",
    initialState,
    reducers: {
        set_schedule_data: (state, action) => (
            {
                ...state,
                ...action.payload
            }
        ),
        set_schedule_reload: (state, action) => (
            {
                ...state,
                reload: state.reload +1
            }
        )
    }
})

export default weeklyScheduleSlice.reducer;

export const {
    set_schedule_data,
    set_schedule_reload
} = weeklyScheduleSlice.actions