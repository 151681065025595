import React, { useState } from "react";
import Link from "./Link";
import NestedLink from "./NestedLink";
import { superAdminLinks, hostessLinks } from "../../routes/NormalRoutes/normalLinks";
import { useDispatch, useSelector } from "react-redux";
import { customStringify, error_alert, removeToken, success_alert } from "../../utils";
import { logout } from "../../redux/slices/userSlice";
import { set_sidebar } from "../../redux/slices/uiSlice";
import { IoIosLogOut } from "react-icons/io";
import { Axios } from "../../api";
import { useLocation } from "react-router-dom";
import { BiLoaderAlt } from "react-icons/bi";
import LanguageSelection from "../LanguageSelection";
import { FormattedMessage, useIntl } from "react-intl";
import { network_tl } from "../../translations";
import { ROLE_ADMIN } from "../../constants";

const Sidebar = () => {
  const sidebar = useSelector((state) => state.ui.sidebar);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const intl = useIntl();
  const links = user.role == ROLE_ADMIN ? superAdminLinks : hostessLinks;
  const location = useLocation();
  const hiddenOnDesktop = location.pathname === "/" || location.pathname === "/valet/dashboard" ? true : false

  const [loading, setLoading] = useState(false);


  const handleLogout = async () => {
    try {
      setLoading(true);
      const result = await Axios.get("api/user/logout", {
        requestId: "logout",
      });
      removeToken();
      dispatch(logout());
      success_alert(intl.formatMessage({ id: "logout_success", defaultMessage: "You have been logout successfully!" }));
      setLoading(false);
    } catch (e) {
      if (e.response) {
        error_alert(customStringify(e.response.data.description));
        setLoading(false);
      } else {
        error_alert(network_tl(intl));
        setLoading(false)
      }
    }
  };

  return (
    <>
      <div
        className={`border-r-2 border-gray-200 z-[101] transition-transform overflow-hidden bg-white flex flex-col fixed top-0 bottom-0 w-[250px] transform ${hiddenOnDesktop ? "translate-x-0" : "lgpad:translate-x-0"} ${sidebar ? "translate-x-0" : "translate-x-[-100%]"
          }`}
      >
        <div className="flex-shrink-0 px-4 mt-3">
          <img src="/images/logo.png" alt="" className="mb-4 w-[60%]" />
        </div>

        <div className="flex-grow space-y-1 overflow-y-auto">
          {links.map((item, index) =>
            item.links ? (
              <NestedLink
                key={index}
                title={item.title}
                Icon={item.icon}
                to={item.to}
                links={item.links}
              />
            ) : (
              <Link key={index} title={item.title} Icon={item.icon} to={item.to} />
            )
          )}
        </div>

        <div className="flex-shrink-0 pb-[50px]">
          <button
            disabled={loading}
            type="button"
            onClick={handleLogout}
            className={`transition-all w-[90%] mx-auto group rounded-lg justify-between flex items-center py-3 px-3 hover:bg-[#E5E5E5]`}
          >
            <div className="flex items-center">
              <IoIosLogOut
                className={`text-xl mr-[20px] opacity-[0.65] hover:opacity-[1]`}
              />
              <span
                className={`tracking-widest uppercase transition-all text-xs text-black/60 group-hover:text-black`}
              >
                {
                  loading ?
                    <BiLoaderAlt className="animate-spin" />
                    :
                    <FormattedMessage id="logout" defaultMessage={"Logout"} />
                }
              </span>
            </div>
          </button>
          <p className="mt-3 text-[9px] transition-all w-[90%] mx-auto group px-3 ">
            V.0.1
          </p>
        </div>
        <LanguageSelection />

      </div>
      {sidebar ? (
        <div
          onClick={() => dispatch(set_sidebar(false))}
          className={`block ${hiddenOnDesktop ? "" : "lgpad:hidden"} bg-black bg-opacity-25 fixed top-0 left-0 right-0 bottom-0 z-[100]`}
        ></div>
      ) : null}
    </>
  );
};

export default Sidebar;
