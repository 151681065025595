import React, {createContext, useContext, useEffect} from 'react'
import {AnimatePresence, motion} from "framer-motion";
import {GrClose} from "react-icons/gr";
import {AiOutlineClose} from "react-icons/ai";
import { POPUP_MEDIUM, POPUP_SMALL, POPUP_TYPE_SIDE, initialPopupState } from '../../constants';
import SidePopup from './SidePopup';

//global context for popup
export const BasePopupContext = createContext(null);

const BasePopup = () => {

    //extracting state from context
    const {popupState} = useContext(BasePopupContext);
    // const {component, heading, size, visible, contained, hideDefaultClose} = popupState;

    // const sizeClass = size === POPUP_SMALL ? "w-full md:w-96 lg:w-6/12 xl:w-5/12 2xl:w-3/12 max-w-[500px]" : size === POPUP_MEDIUM ? "w-full lg:w-7/12 xl:w-6/12 2xl:w-5/12 max-w-[700px]" : "w-10/12 max-w-container";
    return (
            <AnimatePresence>
                {
                    popupState.map((item, index) =>(
                        item.type === POPUP_TYPE_SIDE ?
                            <SidePopup 
                                key={index}
                                data={{
                                    ...item,
                                    index
                                }}
                            />
                        :
                            <PopupContent 
                                index={index}
                                key={index}
                                hideDefaultClose={item.hideDefaultClose}
                                heading={item.heading}
                                contained={item.contained}
                                component={item.component}
                                sizeClass={item.size === POPUP_SMALL ? "w-full md:w-96 lg:w-6/12 xl:w-5/12 2xl:w-5/12 max-w-[700px]" : item.sizeClass === POPUP_MEDIUM ? "w-full lg:w-7/12 xl:w-6/12 2xl:w-5/12 max-w-[700px]" : "w-10/12 max-w-container"}
                            />
                    ))
                }
            </AnimatePresence>
    )
}

const PopupContent = ({heading, contained, hideDefaultClose, component, sizeClass, index}) => {

// this is a helper component which sets the base for a modal
const variants = {
    initial: {
        opacity: 0,
        scale: 0.8,
    },
    enter: {
        opacity: 1,
        scale: 1
    },
    exit: {
        opacity: 0,
        scale: 0.8
    }
}

const {closePopup} = useContext(BasePopupContext);


    return(
        <motion.div 
        initial={{
            opacity: 0
        }}
        animate={{
            opacity: 1
        }}
        exit={{
            opacity: 0
        }}
        className={`bg-black bg-opacity-50 left-0 top-0 fixed h-screen w-screen flex items-start pt-16 pb-8 justify-center overflow-y-auto`} style={{zIndex: `${1000 + index}`}}>
            <motion.div
            transition={{type: "tween"}}
            initial="initial"
            animate="enter"
            exit="exit"
            variants={variants}
            className={`${sizeClass} bg-white shadow-lg rounded-lg border-1 border-gray-200 relative mx-auto`}>
                {/* header */}
                <div className="rounded-lg rounded-b-none p-4 flex items-center justify-between border-b-2 border-gray-100">
                    <p className="text-xl font-bold ">
                        {heading}
                    </p>
                    <div className={`text-white text-3xl cursor-pointer ${hideDefaultClose && "hidden"}`} onClick={closePopup}>
                        <AiOutlineClose/>
                    </div>
                </div>
                {/* header */}
                
                <div className={contained ? "px-5 py-8" : ""}>
                    {component}
                </div>
            </motion.div>
        </motion.div>
    )
}

export default BasePopup
