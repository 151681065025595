import { createSlice } from "@reduxjs/toolkit";
import { DATE_FORMAT_SERVER, DAYS, LUNCH, VIEW_CARD } from "../../constants";
import { DateTime } from "luxon";
import { returnBackendTimeObject } from "../../utils";

const initialState = {
  date: DateTime.now().toFormat(DATE_FORMAT_SERVER),
  tab: LUNCH,
  data: [],
  extra_data: null,
  selected: null,
  scroll_view_id: "",
  view_type: VIEW_CARD,
  notifications: [],
  search: "",
  refresh: 0,
  sidebar: false,
  on_hold: false,
  on_hold_filters: {
    start_date: DateTime.now().minus({years:1}).toFormat(DATE_FORMAT_SERVER),
    end_date: DateTime.now().toFormat(DATE_FORMAT_SERVER),
  },
  end_time_values: returnBackendTimeObject(),
  notification_booking_selected: false
};

const bookingsSlice = createSlice({
  name: "bookings",
  initialState,
  reducers: {
    set_bookings_reset: (state, action) => initialState,
    set_bookings_data: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    set_bookings_refresh: (state, action) => ({
      ...state,
      refresh: state.refresh + 1,
    }),
  },
});

export default bookingsSlice.reducer;

export const { set_bookings_data, set_bookings_refresh, set_bookings_reset } =
  bookingsSlice.actions;
