import React from "react";
import { BiLoaderAlt } from "react-icons/bi";
import { motion } from "framer-motion";

const Loading = () => {
  return (
    <>
      <div className="py-12 w-10/12 mx-auto flex-shrink-0 ">
        <div className="w-11/12 text-center mx-auto">
          <BiLoaderAlt className="mx-auto text-7xl text-primary animate-spin" />
        </div>
      </div>
    </>
  );
};

export default Loading;

export const FullScreenLoading = () => {
  return (
    <div
      className="loader-screen left-0 top-0 fixed h-screen w-screen bg-white flex items-center justify-center"
      style={{ zIndex: 10000 }}
    >
      <h2 className="animate-spin text-7xl text-green-secondary">
        <BiLoaderAlt />
      </h2>
    </div>
  );
};

export const LogoLoading = () => {
  return (
    <div
      className="loader-screen left-0 top-0 fixed h-screen w-screen bg-white flex flex-col items-center justify-center"
      style={{ zIndex: 10000 }}
    >
      <motion.div
        initial={{
          opacity: 0,
          top: "100px",
          scale: 0.4,
        }}
        animate={{
          opacity: 1,
          top: 0,
          scale: 1,
        }}
        transition={{
          duration: 0.5,
        }}
        className="relative flex flex-col items-center justify-center"
      >
        <motion.img
          src="/images/logo.png"
          alt="Valet Logo"
          className="w-full max-w-[200px] relative mx-auto block mb-12"
        />
        <h2 className="animate-spin text-7xl text-green-secondary">
          <BiLoaderAlt />
        </h2>
      </motion.div>
    </div>
  );
};
