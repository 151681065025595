import { createSlice } from "@reduxjs/toolkit";
import { EN } from "../../constants";
import en from "../../languages/en.json";
import { getSelectedLanguage, setSelectedLanguage } from "../../utils";

const initialState = getSelectedLanguage()

const languageSlice = createSlice({
    initialState,
    name: "language-slice",
    reducers: {
        set_language: (state, action) => {
            state= action.payload;
            setSelectedLanguage(state)
            return state
        },
    }
})

export default languageSlice.reducer;

export const {
    set_language,
} = languageSlice.actions;
