// export const BASE_URL = "https://6d49-103-31-104-80.ngrok-free.app/";

import { FormattedMessage } from "react-intl";
// export const BASE_URL = process.env.NODE_ENV === "production" ? process.env.REACT_APP_PRODUCTION_URL : process.env.REACT_APP_DEVELOPMENT_URL;

export const BASE_URL = "https://valet-dev-api.astutesoftwares00.com/";
// export const BASE_URL = "http://192.168.18.177:8000/";
// export const BASE_URL = "http://192.168.0.105:8007/"; //tayyab wifi
// export const BASE_URL = "https://e366-2400-adc5-106-b200-4195-f767-25b7-9d2a.ngrok-free.app/"; // ashar
// export const BASE_URL = "https://cb7a-2400-adc5-106-b200-4809-b214-77ca-f72c.ngrok-free.app/";//tayyab

export const IMAGE_URl = (val) =>
  BASE_URL.substr(0, process.env.REACT_APP_PRODUCTION_URL.length - 1) + val;
export const DATE_FORMAT_SERVER = "yyyy-MM-dd";
export const DATE_FORMAT_DISPLAY = "dd/MM/yyyy";
export const ACCESS_TOKEN = "dukes_access";
export const LANGUAGE_ACCESS = "dukes_language_access";


export const POPUP_SMALL = "small";
export const POPUP_MEDIUM = "medium";
export const POPUP_LARGE = "large";
export const POPUP_TYPE_SIDE = "side";

export const LISTING_LIMIT = 10;

export const initialPopupState = {
  component: null,
  visible: false,
  size: POPUP_MEDIUM,
  heading: "",
  contained: true,
  hideDefaultClose: false,
};

export const BOOKED_VIA_WEB_APP = {
  value: "web app",
  label: "Web App",
};

export const BOOKED_VIA_MOB_APP = {
  value: "mob app",
  label: "Mobile App",
};

export const FILTER_ALL = { value: "", label: "All" };

export const BOOKED_FROM_ARR = [BOOKED_VIA_WEB_APP, BOOKED_VIA_MOB_APP];

export const MAX_CHARS_BIO = 100;
export const SUPPORTED_IMAGES = ["image/png", "image/jpeg"];

export const TIME_SLOTS_CONST = [
  {
    value: 60,
    label: "60 minutes",
  },
  {
    value: 90,
    label: "90 minutes",
  },
  {
    value: 120,
    label: "120 minutes",
  },
  {
    value: 150,
    label: "150 minutes",
  },
  {
    value: 180,
    label: "180 minutes",
  },
];

export const WEEK_CONST = [
  {
    title: "Monday",
    is_active: true,
    is_lunch: true,
    is_dinner: true,
    lunch: {
      start_time: "12:00",
      end_time: "23:00",
    },
    dinner: {
      start_time: "",
      end_time: "",
    },
  },
  {
    title: "Tuesday",
    is_active: true,
    is_lunch: true,
    is_dinner: true,
    lunch: {
      start_time: "",
      end_time: "",
    },
    dinner: {
      start_time: "",
      end_time: "",
    },
  },
  {
    title: "Wednesday",
    is_active: true,
    is_lunch: true,
    is_dinner: true,
    lunch: {
      start_time: "",
      end_time: "",
    },
    dinner: {
      start_time: "",
      end_time: "",
    },
  },
  {
    title: "Thursday",
    is_active: true,
    is_lunch: true,
    is_dinner: true,
    lunch: {
      start_time: "",
      end_time: "",
    },
    dinner: {
      start_time: "",
      end_time: "",
    },
  },
  {
    title: "Friday",
    is_active: true,
    is_lunch: true,
    is_dinner: true,
    lunch: {
      start_time: "",
      end_time: "",
    },
    dinner: {
      start_time: "",
      end_time: "",
    },
  },
  {
    title: "Saturday",
    is_active: true,
    is_lunch: true,
    is_dinner: true,
    lunch: {
      start_time: "",
      end_time: "",
    },
    dinner: {
      start_time: "",
      end_time: "",
    },
  },
  {
    title: "Sunday",
    is_active: true,
    is_lunch: true,
    is_dinner: true,
    lunch: {
      start_time: "",
      end_time: "",
    },
    dinner: {
      start_time: "",
      end_time: "",
    },
  },
];

export const LUNCH = "lunch";
export const DINNER = "dinner";

export const MONDAY = {
  value: "monday",
  label: <FormattedMessage id="monday" defaultMessage={"Monday"} />,
};
export const TUESDAY = {
  value: "tuesday",
  label: <FormattedMessage id="tuesday" defaultMessage={"Tuesday"} />,
};
export const WEDNESDAY = {
  value: "wednesday",
  label: <FormattedMessage id="wednesday" defaultMessage={"Wednesday"} />,
};
export const THURSDAY = {
  value: "thursday",
  label: <FormattedMessage id="thursday" defaultMessage={"Thursday"} />,
};
export const FRIDAY = {
  value: "friday",
  label: <FormattedMessage id="friday" defaultMessage={"Friday"} />,
};
export const SATURDAY = {
  value: "saturday",
  label: <FormattedMessage id="saturday" defaultMessage={"Saturday"} />,
};
export const SUNDAY = {
  value: "sunday",
  label: <FormattedMessage id="sunday" defaultMessage={"Sunday"} />,
};

export const DAYS = [
  MONDAY,
  TUESDAY,
  WEDNESDAY,
  THURSDAY,
  FRIDAY,
  SATURDAY,
  SUNDAY,
];
// export const SOCKET_URL = "wss://valet-dev-api.astutesoftwares00.com/ws/admin/";
export const SOCKET_URL = process.env.NODE_ENV === "production" ? process.env.REACT_APP_PRODUCTION_WS : process.env.REACT_APP_DEVELOPMENT_WS;
export const SOCKET_VALET_URL = process.env.NODE_ENV === "production" ? process.env.REACT_APP_PRODUCTION_VALET_WS : process.env.REACT_APP_DEVELOPMENT_VALET_WS;
export const SOCKET_GLOBAL_URL = process.env.NODE_ENV === "production" ? process.env.REACT_APP_PRODUCTION_GLOBAL_WS : process.env.REACT_APP_DEVELOPMENT_GLOBAL_WS;

export const NOT_ACCEPTABLE = 406;

export const STATUS_BOOKED = {
  value: 'booked',
  label: <FormattedMessage id="booked" defaultMessage={"Booked"}/>,
  bg: "bg-blue-500",
  text: "text-white",
  textColored: "text-blue-500",
  borderColor: "border-blue-500"
}
export const STATUS_ON_TIME_ARRIVAL = {
  value: 'on-time-arrival',
  label: <FormattedMessage id="on_time_arrival" defaultMessage={"On-Time Arrival"}/>,
  bg: "bg-blue-500",
  text: "text-white",
  textColored: "text-blue-500",
  borderColor: "border-blue-500"
}
export const STATUS_LATE_ARRIVAL = {
  value: 'late-arrival',
  label: <FormattedMessage id="late_arrival" defaultMessage={"Late Arrival"}/>,
  bg: "bg-red-500",
  text: "text-white",
  textColored: "text-red-500",
  borderColor: "border-red-500"
}
export const STATUS_COMPLETED = {
  value: 'completed',
  label: <FormattedMessage id="completed" defaultMessage={"Completed"}/>,
  bg: "bg-emerald-500",
  text: "text-white",
  textColored: "text-emerald-500",
  borderColor: "border-emerald-500"
}
export const STATUS_NO_SHOW = {
  value: 'no-show',
  label: <FormattedMessage id="no_show" defaultMessage={"No Show"}/>,
  bg: "bg-amber-500",
  text: "text-white",
  textColored: "text-amber-500",
  borderColor: "border-amber-500"
}
export const STATUS_CANCELLED_ADMIN = {
  value: 'cancelled-admin',
  label: <FormattedMessage id="cancelled_by_admin" defaultMessage={"Cancelled by Admin"}/>,
  bg: "bg-red-500",
  text: "text-white",
  textColored: "text-red-500",
  borderColor: "border-red-500"

}
export const STATUS_CANCELLED_CUSTOMER = {
  value: 'cancelled-customer',
  label: <FormattedMessage id="cancelled_by_customer" defaultMessage={"Cancelled by Customer"}/>,
  bg: "bg-red-500",
  text: "text-white",
  textColored: "text-red-500",
  borderColor: "border-red-500"
}

export const STATUS_NOT_KNOWN = {
  value: null,
  label: <FormattedMessage id="not_known" defaultMessage={'Not Known'}/>,
  bg: "bg-gray-600",
  text: "text-white",
  textColored: "text-gray-600",
  borderColor: "border-gray-600"
}

export const STATUS_ON_HOLD = {
  value: "on-hold",
  label: <FormattedMessage id="on_hold" defaultMessage={'On Hold'}/>,
  bg: "bg-gray-600",
  text: "text-white",
  textColored: "text-gray-600",
  borderColor: "border-gray-600"
}

export const STATUS_CHECK_IN = {
    value: 'check-in',
    label: <FormattedMessage id="check_in" defaultMessage={"Check-In"}/>,
    bg: "bg-emerald-500",
    text: "text-white",
    textColored: "text-emerald-500",
    borderColor: "border-emerald-500"
}
export const STATUS_CHECK_OUT = {
    value: 'check-out',
    label: <FormattedMessage id="check_out" defaultMessage={"Check-Out"}/>,
    bg: "bg-emerald-500",
    text: "text-white",
    textColored: "text-emerald-500",
    borderColor: "border-emerald-500"
}
export const STATUS_REMOVAL = {
    value: 'removal',
    label: <FormattedMessage id="removal" defaultMessage={"Removal"}/>,
    bg: "bg-emerald-500",
    text: "text-white",
    textColored: "text-emerald-500",
    borderColor: "border-emerald-500"
}
export const STATUS_REMITTANCE_CALL  = {
    value: 'remittance-call',
    label: <FormattedMessage id="remittance_call" defaultMessage={"Remittance-Call"}/>,
    bg: "bg-emerald-500",
    text: "text-white",
    textColored: "text-emerald-500",
    borderColor: "border-emerald-500"
}
export const STATUS_REMITTANCE_WITHDRAWN   = {
    value: 'remittance-withdrawn',
    label: <FormattedMessage id="remittance_withdraw" defaultMessage={"Remittance-Withdrawn"}/>,
    bg: "bg-emerald-500",
    text: "text-white",
    textColored: "text-emerald-500",
    borderColor: "border-emerald-500"
}
export const STATUS_IN_GARAGE  = {
    value: 'in-garage',
    label: <FormattedMessage id="in_garage" defaultMessage={"In Garage"}/>,
    bg: "bg-emerald-500",
    text: "text-white",
    textColored: "text-emerald-500",
    borderColor: "border-emerald-500"
}
export const STATUS_PICKED_UP  = {
    value: 'picked-up',
    label: <FormattedMessage id="picked_up" defaultMessage={"Picked Up"}/>,
    bg: "bg-emerald-500",
    text: "text-white",
    textColored: "text-emerald-500",
    borderColor: "border-emerald-500"
}

export const VALET_STATUS_ARR = [
  STATUS_BOOKED,
  STATUS_CHECK_IN,
  STATUS_CHECK_OUT,
  STATUS_IN_GARAGE,
  STATUS_REMITTANCE_CALL,
  STATUS_PICKED_UP,
  STATUS_REMITTANCE_WITHDRAWN,
  STATUS_CANCELLED_ADMIN,
]
export const VALET_STATUS_ARR_ALL = [
  STATUS_BOOKED,
  STATUS_CHECK_IN,
  STATUS_CHECK_OUT,
  STATUS_IN_GARAGE,
  STATUS_REMITTANCE_CALL,
  STATUS_PICKED_UP,
  STATUS_REMITTANCE_WITHDRAWN,
  STATUS_NOT_KNOWN,
  STATUS_CANCELLED_ADMIN,
  STATUS_CANCELLED_CUSTOMER,
  STATUS_ON_HOLD
]

export const STATUS_ARR_ALL = [
STATUS_BOOKED,
STATUS_ON_TIME_ARRIVAL,
STATUS_LATE_ARRIVAL,
STATUS_COMPLETED,
STATUS_NO_SHOW,
STATUS_CANCELLED_ADMIN,
STATUS_CANCELLED_CUSTOMER,
STATUS_NOT_KNOWN,
STATUS_ON_HOLD
]
export const STATUS_ARR = [
STATUS_BOOKED,
STATUS_ON_TIME_ARRIVAL,
STATUS_LATE_ARRIVAL,
STATUS_COMPLETED,
STATUS_NO_SHOW,
STATUS_CANCELLED_ADMIN,
STATUS_CANCELLED_CUSTOMER,
]

export const USER_STATUS_ARR = [
  {
    value: "active",
    label: <FormattedMessage id="active" defaultMessage={"Active"} />
  },
  {
    value: "disable",
    label: <FormattedMessage id="disabled" defaultMessage={"Disabled"} />
  },
]


export const DATE_GROUP_FILTER = [
  {
    value: "past",
    label: <FormattedMessage id="past" defaultMessage={"Past"} />
  },
  {
    value: "current",
    label: <FormattedMessage id="current" defaultMessage={"Current"} />
  },
  {
    value: "upcoming",
    label: <FormattedMessage id="upcoming" defaultMessage={"Upcoming"} />
  },
]

export const SOCKET_ADD_BOOKING = "add_user_booking"
export const SOCKET_DELETE_BOOKING = "delete_user_booking"
export const SOCKET_UPDATE_BOOKING = "update_user_booking"
export const SOCKET_STATUS_NO_SHOW = "cron_booking_update"

// export const PHONE_REGEX = /^\+\d{7,15}$/;
export const PHONE_REGEX = /^[+][0-9]{10,14}$/;
// export const PHONE_REGEX =  /^\+(39|0039)\d{9,12}$/; //italy regex

export const VIEW_CARD = {
  value: "card",
  label: <FormattedMessage id="card_view" defaultMessage={"Card View"} />
};
export const VIEW_SLOT = {
  value: "slot",
  label: <FormattedMessage id="slot_view" defaultMessage={"Slot View"} />
};
export const VIEW_TABLE = {
  value: "table",
  label: <FormattedMessage id="table_view" defaultMessage={"Table View"} />
}

export const VIEW_ARR = [
  VIEW_CARD,
  VIEW_SLOT,
  VIEW_TABLE
]

export const GLOBAL_SOCKET_TABLE_ADD = "table_booking_created"
export const GLOBAL_SOCKET_TABLE_UPDATE = "table_booking_updated"
export const GLOBAL_SOCKET_TABLE_DELETE = "table_booking_canceled"
export const GLOBAL_SOCKET_VALET_ADD = "valet_booking_created"
export const GLOBAL_SOCKET_VALET_UPDATE = "valet_booking_updated"
export const GLOBAL_SOCKET_VALET_DELETE = "valet_booking_canceled"



export const EN = "en"
export const IT = "it"

export const ROLE_ADMIN = "admin"
export const ROLE_HOSTESS = "hostess"


export const customPaginationText=(intl) => {
  return {
  rowsPerPageText: <FormattedMessage id="rows_per_page" defaultMessage={"Rows Per Page"}/>,
  rangeSeparatorText: intl.formatMessage({id:"of", defaultMessage:"of"})
  }
};
