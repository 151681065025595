import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    sidebar: false,
    setup: true,
}

const uiSlice = createSlice({
    initialState,
    name: "ui",
    reducers: {
        set_sidebar: (state, action) => {
            state.sidebar = action.payload;
            return state
        },
        set_setup: (state, action) => {
            state.setup = action.payload;
            return state
        },
    }
})

export default uiSlice.reducer;

export const {
    set_sidebar,
    set_setup
} = uiSlice.actions;
