import { LiaWineGlassAltSolid } from "react-icons/lia";
import { GrTemplate } from "react-icons/gr";
import { IoCarSportOutline, IoCalendarOutline } from "react-icons/io5";
import { LuUsers2 } from "react-icons/lu";
import { MdOutlineSpaceDashboard } from "react-icons/md";
import { IoPeopleOutline } from "react-icons/io5";
import { FormattedMessage } from "react-intl";

export const hostessLinks = [
    {
        title: <FormattedMessage id="table_dashboard" defaultMessage={"Table Dashboard"} />,
        icon: MdOutlineSpaceDashboard,
        to: "/",
    },
    {
        title: <FormattedMessage id="valet_das" defaultMessage={"Valet Dashboard"} />,
        icon: MdOutlineSpaceDashboard,
        to: "/valet/dashboard",
    },
    {
        title: <FormattedMessage id="all_table_bookings" defaultMessage={"All Bookings"} />,
        icon: LiaWineGlassAltSolid,
        to: "/bookings",
    },
    {
        title: <FormattedMessage id="all_v_b" defaultMessage={"All Valet Bookings"} />,
        icon: IoCarSportOutline,
        to: "/valet/bookings",
    },
];

export const superAdminLinks = [
    {
        title: <FormattedMessage id="table_dashboard" defaultMessage={"Table Dashboard"} />,
        icon: MdOutlineSpaceDashboard,
        to: "/",
    },
    {
        title: <FormattedMessage id="valet_das" defaultMessage={"Valet Dashboard"} />,
        icon: MdOutlineSpaceDashboard,
        to: "/valet/dashboard",
    },

    {
        title: <FormattedMessage id="table_schedule" defaultMessage={"Table Schedule"} />,
        icon: IoCalendarOutline,
        to: "/",
        links: [

            {
                title: <FormattedMessage id="weekly_schedule" defaultMessage={"Weekly Schedule"} />,
                icon: IoCalendarOutline,
                to: "/weekly-schedule",
            },
            {
                title: <FormattedMessage id="advanced_schedule" defaultMessage={"Advanced Schedule"} />,
                icon: LiaWineGlassAltSolid,
                to: "/special-events",
            },
        ],
    },
    {
        title: <FormattedMessage id="valet_schedule" defaultMessage={"Valet Schedule"} />,
        icon: IoCalendarOutline,
        to: "/",
        links: [

            {
                title: <FormattedMessage id="weekly_schedule" defaultMessage={"Weekly Schedule"} />,
                icon: IoCalendarOutline,
                to: "/valet/weekly-schedule",
            },
            {
                title: <FormattedMessage id="advanced_schedule" defaultMessage={"Advanced Schedule"} />,
                icon: LiaWineGlassAltSolid,
                to: "/valet/special-events",
            },
        ],
    },

    {
        title: <FormattedMessage id="customers" defaultMessage={"Customers"} />,
        icon: LuUsers2,
        to: "/customers",
    },
    {
        title: <FormattedMessage id="all_table_bookings" defaultMessage={"All Bookings"} />,
        icon: LiaWineGlassAltSolid,
        to: "/bookings",
    },
    {
        title: <FormattedMessage id="all_v_b" defaultMessage={"All Valet Bookings"} />,
        icon: IoCarSportOutline,
        to: "/valet/bookings",
    },
    {
        title: <FormattedMessage id="table_templates" defaultMessage={"Table Templates"} />,
        icon: GrTemplate,
        to: "/templates",
    },
    {
        title: <FormattedMessage id="valet_templates" defaultMessage={"Valet Templates"} />,
        icon: GrTemplate,
        to: "/valet/templates",
    },
    {
        title: <FormattedMessage id="drivers" defaultMessage={"Drivers"} />,
        icon: IoCarSportOutline,
        to: "/drivers",
    },
    {
        title: <FormattedMessage id="hostesses" defaultMessage={"Hostesses"} />,
        icon: IoPeopleOutline,
        to: "/hostesses",
    },
];
