import React from "react";
import { useDispatch } from "react-redux";
import { HiOutlineMenuAlt3 } from "react-icons/hi";
import { set_sidebar } from "../../redux/slices/uiSlice";
import Notifications from "../Notifications";

const Page = ({ children, title }) => {
  const dispatch = useDispatch();

  return (
    <>
      <div className="lgpad:pl-[249px] w-full">
        <div className="border-b-2 border-gray-200 flex items-center justify-between w-full mx-auto mb-5 mt-0 py-3 bg-white px-5">

          <h1 className="text-base font-medium text-black/80 uppercase">
            {title}
          </h1>
          <div className="flex items-center space-x-3">
            <button
              onClick={() => {
                dispatch(set_sidebar(true));
              }}
              className="block lgpad:hidden"
            >
              <HiOutlineMenuAlt3 className="text-2xl text-black" />
            </button>
            <Notifications />
          </div>
        </div>

        <div className="w-[95%] max-w-[1600px] mx-auto pb-10">
          {
            children
          }
        </div>
      </div>
    </>
  );
};

export default Page;
