import React, { Suspense } from "react";
import { Route, Routes, Navigate } from "react-router";

import { LogoLoading } from "../../components/Loading";
import Sidebar from "../../components/Sidebar";
import { useSelector } from "react-redux";
import { ROLE_ADMIN } from "../../constants";

// first time setup
// first time setup

// page imports
const Setup = React.lazy(() => import("../../components/Setup"));
const GlobalSocketWrapper = React.lazy(() => import("../../components/GlobalSocketWrapper"));
const Dashboard = React.lazy(() => import("../../pages/Dashboard"));
const ValetBookings = React.lazy(() => import("../../pages/ValetBookings"));
const Profile = React.lazy(() => import("../../pages/Profile"));
const Template = React.lazy(() => import("../../pages/Template"));
const AddTemplate = React.lazy(() => import("../../pages/AddTemplate"));
const EditTemplate = React.lazy(() => import("../../pages/EditTemplate"));
const WeeklySchedule = React.lazy(() => import("../../pages/WeeklySchedule"));
const Bookings = React.lazy(() => import("../../pages/Bookings"));
const BookingCalendar = React.lazy(() => import("../../pages/BookingCalendar"));
const AddSpecialEvent = React.lazy(() => import("../../pages/AddSpecialEvent"));
const SpecialEvents = React.lazy(() => import("../../pages/SpecialEvents"));
const Drivers = React.lazy(() => import("../../pages/Drivers"));
const Hostesses = React.lazy(() => import("../../pages/Hostess"));
const Schedule = React.lazy(() => import("../../pages/Schedule"));
const Customers = React.lazy(() => import("../../pages/Customers"));
const NotificationSidebar = React.lazy(() => import("../../components/NotificationSidebar"));
const AddValetTemplate = React.lazy(() => import("../../pages/AddValetTemplate"));
const EditValetTemplate = React.lazy(() => import("../../pages/EditValetTemplate"));
const ValetTemplates = React.lazy(() => import("../../pages/ValetTemplates"));
const ValetWeeklySchedule = React.lazy(() => import("../../pages/ValetWeeklySchedule"));
const ValetSpecialEvents = React.lazy(() => import("../../pages/ValetSpecialEvents"));
const ValetAddSpecialEvent = React.lazy(() => import("../../pages/ValetAddSpecialEvent"));
const ValetDashboard = React.lazy(() => import("../../pages/ValetDashboard"));
const PrivacyPolicy = React.lazy(() => import("../../pages/PrivacyPolicy"));
const Notifications = React.lazy(() => import("../../pages/Notifications"));

// page imports

const AdminRoutes = () => {
  const setup = useSelector((state) => state.ui.setup);
  const user = useSelector((state) => state.user);

  if (!setup) {
    return (
      <>
        <Setup />
      </>
    );
  } else {
    return (
      <Suspense fallback={<LogoLoading />}>

        <GlobalSocketWrapper>
          <Sidebar />
          <NotificationSidebar />
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/bookings" element={<Bookings />} />

            <Route path="/valet/dashboard" element={<ValetDashboard />} />
            <Route path="/valet/bookings" element={<ValetBookings />} />

            <Route path="/notifications" element={<Notifications />} />

            {user.role == ROLE_ADMIN &&
              <>
                <Route path="/customers" element={<Customers />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/templates" element={<Template />} />
                <Route path="/templates/add" element={<AddTemplate />} />
                <Route path="/templates/:id/edit" element={<EditTemplate />} />
                <Route path="/weekly-schedule" element={<WeeklySchedule />} />
                <Route path="/booking-calendar" element={<BookingCalendar />} />
                <Route path="/special-events" element={<SpecialEvents />} />
                <Route path="/special-events/add" element={<AddSpecialEvent />} />
                <Route path="/drivers" element={<Drivers />} />
                <Route path="/hostesses" element={<Hostesses />} />
                <Route path="/schedule" element={<Schedule />} />

                <Route path="/valet/templates" element={<ValetTemplates />} />
                <Route path="/valet/templates/add" element={<AddValetTemplate />} />
                <Route path="/valet/templates/:id/edit" element={<EditValetTemplate />} />
                <Route path="/valet/weekly-schedule" element={<ValetWeeklySchedule />} />
                <Route path="/valet/special-events" element={<ValetSpecialEvents />} />
                <Route path="/valet/special-events/add" element={<ValetAddSpecialEvent />} />
              </>
            }

            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </GlobalSocketWrapper>
      </Suspense>
    );
  }
};

export default AdminRoutes;
