import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  sidebar_api_data: [],
  unread_count: 0,
  sidebar: false,
};

// set_notification_data({data: [data]})

const globalNotificationReducer = createSlice({
  name: "global_notifications",
  initialState,
  reducers: {
    set_global_notifications: (state, action) => ({
      ...state,
      ...action.payload,
    }),
  },
});

export default globalNotificationReducer.reducer;

export const {
  set_global_notifications
} = globalNotificationReducer.actions;
