import { createSlice } from "@reduxjs/toolkit";
import { DateTime } from "luxon";
import { DATE_FORMAT_SERVER } from "../../constants";

const initialState = {
    date: {
        start_date: DateTime.now().toFormat(DATE_FORMAT_SERVER),
        end_date: DateTime.now().toFormat(DATE_FORMAT_SERVER),
        key: 'selection',
        color: "#000"
        // showDateDisplay: false
    },
    calendar_view: true,
    data: null,
    templates: [],
    selectedTemplate: null,
    templateData: null,
    reload: 0,
}

const specialEventSlice = createSlice({
    name: "special_event",
    initialState,
    reducers: {
        set_special_data: (state, action) => (
            {
                ...state,
                ...action.payload
            }
        ),
        set_special_reload: (state, action) => (
            {
                ...state,
                reload: state.reload +1
            }
        ),
        reset_special: (state, action) => (initialState),
    }
})

export default specialEventSlice.reducer;

export const {
    set_special_data,
    set_special_reload,
    reset_special
} = specialEventSlice.actions