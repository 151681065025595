import React from "react";

import { useDispatch, useSelector } from "react-redux";
import { set_global_notifications } from "../../redux/slices/globalNotificationsSlice";

import { IoIosNotificationsOutline } from "react-icons/io";

const Notifications = ({light}) => {
  const dispatch = useDispatch();
  const sidebar = useSelector((state) => state.global_notifications.sidebar);
  const unread_count = useSelector(state => state.global_notifications.unread_count);

  return (
    <>
      <button
        type="button"
        onClick={() => dispatch(set_global_notifications({
          sidebar: !sidebar
        }))}
        className={`relative flex items-center justify-center h-10 w-10 bg-transparent rounded-lg ${light ? "hover:bg-white/30" : "hover:bg-gray-200"}`}
      >
        <IoIosNotificationsOutline className={`text-2xl ${light ? "text-white" : ""}`} />
        <div className="absolute top-1 right-1 h-4 w-4 rounded-full bg-red-500 flex items-center justify-center">
          <p className="text-white text-[10px] font-semibold">
            {unread_count>=9? "9+": unread_count}
          </p>
        </div>
      </button>
    </>
  );
};

export default Notifications;
