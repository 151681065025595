import { AnimatePresence, motion } from "framer-motion";
import React, { useState } from "react";
import { TfiAngleRight } from "react-icons/tfi";
import { BiCog } from "react-icons/bi";
import { Link, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { set_sidebar } from "../../redux/slices/uiSlice";

const NestedLink = ({ Icon, title, to, links }) => {
  const [open, setOpen] = useState(false);
  const { pathname } = useLocation();
  const dispatch = useDispatch()
  // const hovered = ;
  return (
    <>
      <div className="relative">
        <button
          onClick={() => setOpen(!open)}
          className={`w-[90%] mx-auto group rounded-lg justify-between flex items-center py-3 px-3`}
        >
          <div className="flex items-center">
            {/* <img src={icon} className={`flex-shrink-0 mr-3 w-[20px] ${open ? "" : "opacity-[0.65]"}`} /> */}
            <Icon
              className={`text-xl mr-[20px] ${
                pathname === to ? "" : "opacity-[0.65]"
              }`}
            />
            <span
              className={`tracking-widest text-start transition-all uppercase text-xs ${
                open
                  ? "text-black"
                  : "text-black/60 group-hover:text-black"
              }`}
            >
              {title}
            </span>
          </div>
          <TfiAngleRight
            className={`transition-all text-xl transform ${
              open
                ? "rotate-[90deg] text-black"
                : "text-black/60 group-hover:text-black rotate=[0deg]"
            }`}
          />
        </button>
        <AnimatePresence>
          {open && (
            <motion.div
              initial={{ height: "0" }}
              animate={{ height: "auto" }}
              exit={{ height: "0" }}
              className="pl-5 overflow-hidden w-[90%] mx-auto relative"
            >
              <div className="border-l-2 border-dashed border-primary/20 space-y-1">
                {links.map((item, index) => (
                  <Link
                    key={index}
                    to={item.to}
                    onClick={() => {
                      dispatch(set_sidebar(false))
                    }}
                    className={`transition-all  w-[90%] mx-auto group hover:bg-secondary rounded-lg justify-start flex items-center py-4 px-3 ${
                      item.to === pathname ? "bg-secondary" : ""
                    }`}
                  >
                    <span
                      className={`tracking-widest text-[10px] uppercase ${
                        item.to === pathname
                          ? "font-semibold text-black"
                          : "text-black/70 group-hover:text-black"
                      }`}
                    >
                      {item.title}
                    </span>
                  </Link>
                ))}
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </>
  );
};

export default NestedLink;
