import React, { Suspense } from "react";
import { Route, Routes, Navigate } from "react-router";

import { LogoLoading } from "../../components/Loading";
import PrivacyPolicy from "../../pages/PrivacyPolicy";

const Login = React.lazy(() => import("../../pages/Login"));
const ForgotPassword = React.lazy(() => import("../../pages/ForgotPassword"));
const ResetPassword = React.lazy(() => import("../../pages/ResetPassword"));

function AuthRoute() {
  return (
    <>
      <Suspense fallback={<LogoLoading />}>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password/:id" element={<ResetPassword />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy no_sidebar={true}/>} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Suspense>
    </>
  );
}

export default AuthRoute;
