import { configureStore } from "@reduxjs/toolkit";

//reducers below
import userReducer from "./slices/userSlice";
import uiReducer from "./slices/uiSlice";
import weeklyScheduleReducer from "./slices/weeklyScheduleSlice";
import bookingsReducer from "./slices/bookingsSlice";
import valetBookingsReducer from "./slices/valetBookingsSlice";
import specialEventReducer from "./slices/specialEventSlice";
import globalNotificationsReducer from "./slices/globalNotificationsSlice";
import languageReducer from "./slices/languageSlice";
//reducers above

export default configureStore({
    reducer:{
        user: userReducer,
        ui: uiReducer,
        weekly_schedule: weeklyScheduleReducer,
        bookings: bookingsReducer,
        valet_bookings: valetBookingsReducer,
        special_event: specialEventReducer,
        global_notifications: globalNotificationsReducer,
        language:languageReducer
    } 
})
