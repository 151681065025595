import React from 'react'
import { MdLanguage } from "react-icons/md";
import { FormattedMessage, useIntl } from 'react-intl';
import { Tooltip } from 'react-tooltip'
import { set_language } from '../../redux/slices/languageSlice';
import { useDispatch, useSelector } from 'react-redux';
import { EN, IT } from '../../constants';
import { set_sidebar } from '../../redux/slices/uiSlice';
import en from "../../languages/en.json";
import it from "../../languages/it.json";


const LanguageSelection = () => {
    const intl = useIntl()
    const dispatch = useDispatch();

    const language = useSelector((state) => state.language)


    const handleChange = (e) => {
        e.stopPropagation()
        if (language.type === EN) {
            let obj = {
                type: IT,
                // messages: it
            }
            dispatch(set_language(obj))
        } else {
            let obj = {
                type: EN,
                // messages: en
            }
            dispatch(set_language(obj))
        }
        dispatch(set_sidebar(false))
    }


    return (
        <div className={`transition-all w-[90%] mx-auto group rounded-lg justify-between flex items-center py-3 px-3 hover:bg-secondary `}>
            <div className="flex w-full  justify-between items-center">
                <div className='flex '>
                    <MdLanguage
                        className={`text-xl mr-[20px]`}
                    />
                    <span className={`tracking-widest uppercase transition-all text-xs`}>
                        {
                            language.type === EN  ?
                                <FormattedMessage id="english" defaultMessage="English" />
                            :
                                <FormattedMessage id="italian" defaultMessage="Italian" />
                        }
                    </span>
                </div>

                <div className='inline-flex'>
                    {
                        language.type === IT &&
                        <p className='pr-1 text-[10px]'><FormattedMessage id='en' defaultMessage={"en"}/></p>
                    }
                    <label
                        data-tooltip-id={`language_tooltip`} data-tooltip-content={language.type === EN ? intl.formatMessage({ id: "italian", defaultMessage: "italian" }) : intl.formatMessage({ id: "english", defaultMessage: "English" })}
                        className="relative inline-flex items-center cursor-pointer">
                        <input type="checkbox"
                            value=""
                            checked={language.type === IT  }
                            class="sr-only peer" onChange={(e) => handleChange(e)} />
                        <div class="w-[28px] h-[15px] bg-gray-200 border-[1px] rounded-full peer dark:bg-gray-50 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2.5px]  after:left-[1px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-[10px] after:w-3 after:transition-all dark:border-gray-600 peer-checked:bg-primary"></div>

                    </label>
                    <Tooltip id="language_tooltip" />
                    {
                        language.type === EN &&
                        <p className='pl-1 text-[10px]'><FormattedMessage id='it' defaultMessage={"IT"}/></p>
                    }
                </div>

            </div>
        </div>
    )
}

export default LanguageSelection