import React from "react";
import { useDispatch } from "react-redux";
import { useLocation, Link } from "react-router-dom";
import { set_sidebar } from "../../redux/slices/uiSlice";

const LinkContainer = ({ Icon, title, to }) => {
  const { pathname } = useLocation();
  const dispatch = useDispatch()
  return (
    <>
      <Link
        onClick={() => {
          dispatch(set_sidebar(false))
        }}
        to={to}
        className={`transition-all w-[90%] mx-auto group rounded-lg justify-between flex items-center py-3 px-3 hover:bg-secondary ${
          pathname === to ? "bg-secondary" : ""
        }`}
      >
        <div className="flex items-center">
          {/* <img src={icon} className={`flex-shrink-0 mr-3 w-[20px] ${pathname === to ? "" : "opacity-[0.65]"}`} /> */}
          <Icon
            className={`text-xl mr-[20px] ${
              pathname === to ? "" : "opacity-[0.65]"
            }`}
          />
          <span
            className={`tracking-widest uppercase transition-all text-xs ${
              pathname === to
                ? "font-semibold text-black"
                : "text-black/60 group-hover:text-black"
            }`}
          >
            {title}
          </span>
        </div>
      </Link>
    </>
  );
};

export default LinkContainer;
