import React, { useState } from "react";
//redux imports
import { Provider, useSelector } from "react-redux";
import store from "./redux/store";
//redux imports

// notifications alerts
import "react-notifications-component/dist/theme.css";
import {ReactNotifications} from "react-notifications-component";
import "animate.css/animate.min.css";

// notifications alerts

//multilingual imports
import { IntlProvider } from "react-intl";
import en from "./languages/en.json";
import it from "./languages/it.json";
//multilingual imports

//router
import { BrowserRouter } from "react-router-dom";
import BaseRoutes from "./routes";
//router

//global popup
import BasePopup, { BasePopupContext } from "./components/BasePopup";
//global popup

//react-big-calendar
import "react-big-calendar/lib/css/react-big-calendar.css";
//react-big-calendar

//react-toastify
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { EN } from "./constants";
//react-toastify

const App = () => {
  const [popupState, setPopupState] = useState([]);
  const language = useSelector((state) => state.language)


  const openPopup = (params) => setPopupState((old) => [...old, params]);
  const closePopup = () =>
    setPopupState((old) =>
      old.filter((item, index) => (index !== old.length - 1 ? true : false))
    );
  const closeAllPopup = () => setPopupState([]);

  const global_popup_context_data = {
    popupState,
    setPopup: openPopup,
    closePopup,
    closeAllPopup,
  };
  // state for popup

  return (
    <>
      
      <IntlProvider messages={language.type=== EN? en : it } locale={'en'} defaultLocale="en">
          <BasePopupContext.Provider value={global_popup_context_data}>
            <ToastContainer />
            <BrowserRouter>
              <ReactNotifications />
              <BaseRoutes />
              <BasePopup />
            </BrowserRouter>
          </BasePopupContext.Provider>
      </IntlProvider>
    </>
  );
};

export default App;
