import React from 'react'

const Italian = ({bg_white}) => {
  return (
    <div className={bg_white ? "bg-white py-10 px-5 rounded-tr-lg rounded-tl-lg border-2 border-gray-200" : ""}>
        
        <p className='font-bold text-lg mb-5'>
        Ai sensi dell’art. del decreto legislativo n. 196 del 30 giugno 2003 (di seguito, il "D.Lgs. n. 196/2003"), la informiamo che:
        </p>
        <p className='text-lg mb-5'>
        <strong>a)</strong> i suoi dati personali (non comprendenti dati qualificabili come sensibili e/o giudiziari) saranno oggetto del trattamento di cui all’art. 4 del D.Lgs. n. 196/2003 al fine di inviarle il materiale informativo, promozionale e pubblicitario relativo ai servizi e prodotti del Duke's;
        </p>
        <p className='text-lg mb-5'>
        <strong>b)</strong> i suoi dati saranno oggetto di trattamento sia in forma cartacea che mediante strumenti elettronici;
        </p>
        <p className='text-lg mb-5'>
        <strong>c)</strong> il conferimento dei suoi dati personali ha natura facoltativa per quanto riguarda le finalità relative all’invio del materiale promo-pubblicitario di cui alla lettera a) che precede;
        </p>
        <p className='text-lg mb-5'>
        <strong>d)</strong> ai sensi dell’art. 7 del D.Lgs. 196/2003, lei ha il diritto in qualunque momento di ottenere la conferma dell’esistenza o meno dei suoi dati e di conoscerne il contenuto e l’origine, verificarne l’esattezza o chiederne l’integrazione o l’aggiornamento, oppure la rettificazione. Ai sensi del medesimo articolo, lei ha il diritto di chiedere la cancellazione, la trasformazione in forma anonima o il blocco dei dati trattati in violazione di legge, nonché di opporsi in ogni caso, per motivi legittimi, al loro trattamento.
        </p>
        
        <p className='text-lg mb-5'>
        Agli effetti del D.Lgs. n. 196/2003 ed in particolare per far valere i suoi diritti di cui all’articolo 7 del D.Lgs. n.196/2003: Titolare e responsabile del trattamento è il Duke's
        </p>
        <p className='text-lg mb-5'>
        La invitiamo a sottoscrivere la presente informativa esprimendo, in tal modo, il suo consenso al trattamento dei suoi dati personali, laddove necessario.
        </p>
    </div>
  )
}

export default Italian