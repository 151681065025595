import React from 'react'
import Page from '../../components/Page'
import Italian from './Italian'
import LanguageSelection from '../../components/LanguageSelection/AtAuthPages'

const PrivacyPolicy = ({no_sidebar}) => {
  return (
    no_sidebar ?
        <div className=''>
            <div className='max-w-[1600px] mx-auto w-[90%]'>
            <LanguageSelection/>
                <div className='py-10 px-5 mt-10 rounded-tr-lg rounded-tl-lg border-2 border-gray-200 bg-white min-h-[100vh]'>
                    <h1 className='text-3xl font-bold text-center mb-10 uppercase'>
                        Privacy Policy
                    </h1>

                    <Italian/>
                </div>
            </div>
        </div>
    :    
        <Page title="Privacy Policy">
            <Italian bg_white={true}/>
        </Page>
  )
}

export default PrivacyPolicy